import React, { Component } from 'react';
import articleMapDataToComponents, {
  articleFaqMap
} from '../components/article/articleMapDataToComponents';

import ArticleIntro from '../components/article/articleIntro';
import ContentBackground from '../components/ui/contentBackground';
import FaqList from '../components/article/faqList';
import HeadTags from '../components/global/headTags';
import ImpactedRegions from '../components/article/impactedRegions';
import Reveal from '../components/ui/reveal';
import SocialShare from '../components/global/socialShare';
import VideoEmbed from '../components/basic/videoEmbed';
import { graphql } from 'gatsby';
import translations from '../utils/translations';
import DefaultLayout from '../layouts/default-layout';
import ArticleThemeContext from '../context/articleThemeContext';
import ArticleList from '../components/article/articleList';

class Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
    };
    this.articleType = null;
    if (props.handlePageDataChanged && props.data.itemsJson.article_type) {
      props.handlePageDataChanged({ articleType: props.data.itemsJson.article_type });
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    const newArticleType = nextProps.data.itemsJson.article_type;
    if (nextProps.handlePageDataChanged && newArticleType !== this.articleType) {
      this.articleType = newArticleType;
      nextProps.handlePageDataChanged({ articleType: newArticleType });
    }
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }
  render() {
    const {
      title,
      created_date,
      archive,
      authors,
      meta_description,
      related_challenge,
      featured_image,
      featured_video,
      video,
      related_impacted_regions,
      make_this_article_faq,
      prevent_indexing
    } = this.props.data.itemsJson;
    const {
      popular_stories
    } = this.props.data;

    const paragraphs = this.props.data.itemsJson.paragraphs.filter(p => p.type !== 'partnership');
    
    // const latestArticleStories = this.props.data.megaMenu.edges.find(e => e.node.lang === this.state.lang).node.latest_article_stories;

    return (
      <DefaultLayout {...this.props} excludeParentFunctions>
        <ArticleThemeContext.Provider value="dark">
          <div
            // style={this.props.transition && this.props.transition.style}
            // for some reason, fading out the whole article resulted in a long page getting
            // cut off. Some rendering issue. So instead we fade in a dark overlay on top of the content.
            className={
              this.props.transition && this.props.transition.status === 'exiting'
                ? 'article-exiting'
                : ''
            }
          >
            <HeadTags
              title={title}
              description={meta_description}
              image={featured_image ? featured_image.image.sizes.hero_md : null}
              imageTwitter={
                featured_image ? featured_image.image.sizes.hero_sm : null
              }
              preventIndexing={prevent_indexing}
              location={this.props.location}
              isArticle
            />
            {!featured_video &&
              <ContentBackground
                src={featured_image ? featured_image.image.sizes.hero_lg : ''}
                videoSrc={video ? video.url : ''}
                videoOpacity={0.4}
              />
            }
            {featured_video &&
              <ArticleIntro
                title={title}
                created_date={created_date}
                label={related_challenge.title}
                archive={archive}
                authors={authors}
                isIE={this.props.isIE}
                backButton={!!related_impacted_regions}
                history={this.props.history}
                small
              />
            }
            {!featured_video &&
              <ArticleIntro
                title={title}
                created_date={created_date}
                label={related_challenge.title}
                archive={archive}
                authors={authors}
                isIE={this.props.isIE}
                backButton={!!related_impacted_regions}
                history={this.props.history}
              />
            }
            {featured_video &&
              <div className="text-block">
                <VideoEmbed
                  heading={''}
                  imgSrc={featured_video.image ? featured_video.image.sizes.standard_md : ''}
                  videoUrl={featured_video.url}
                  videoId={featured_video.id}
                  isFeatured
                  small
                />
              </div>
            }
            <div className="article-content">
              {make_this_article_faq === '1' && <FaqList paragraphs={paragraphs} />}

              {make_this_article_faq === '1'
                ? articleFaqMap(paragraphs, this.state.width, make_this_article_faq === '1')
                : articleMapDataToComponents(
                  paragraphs,
                  popular_stories,
                  {
                    width: this.state.width,
                    theme: 'dark',
                  },
                  make_this_article_faq === '1'
                )}

              {related_impacted_regions && (
                <Reveal>
                  <ImpactedRegions
                    calloutHeading={translations.t('Featured Regions')}
                    climateTrendPath={this.props.data.climateTrends.path}
                    regions={related_impacted_regions.map(item => ({
                      title: item.title,
                      url: item.path,
                      regionId: item.region_id,
                    }))}
                  />
                </Reveal>
              )}
            </div>
            
            {/*}
            {latestArticleStories
                && <div className={style.relatedReading}>
                  <ArticleList theme="off-white"
                    articles={latestArticleStories}
                    label={translations.t("Related Reading")}
                  />
                </div>
              }
              */}
              
            <SocialShare title={title} />
          </div>
        </ArticleThemeContext.Provider>
      </DefaultLayout>
    );
  }
}

export default Article;

// eslint-disable-next-line
export const articlePageQuery = graphql`
  query ArticlePageContentByPath(
    $path: String!,
    $climateTrendId: String!,
    $popularStoriesId: String!,
    $isEs: Boolean!,
    $idRegex: String!,
    $blog: String!,
    $translations: String!) {
   itemsJson(path: { eq: $path }) {
      title
			created_date
      short_description
      meta_description
      article_type
      related_challenge {
        title
      }
      archive {
        title
        path
      }
      authors {
        id
        name
      }
      make_this_article_faq
      featured_image {
        image {
          sizes {
            hero_lg
            hero_md
            hero_sm
            original
          }
        }
      }
      video {
        url
      }
      related_impacted_regions {
        content_type
        path
        title
        region_id
      }
      featured_video {
        id
        url
        caption
        image {
          sizes {
            original
            standard_md
            standard_sm
          }
        }
      }
      prevent_indexing
      ...queryParagraphFields
    }
    climateTrends: itemsJson(jsonId: { eq: $climateTrendId }) {
      path
    }
    popular_stories: itemsJson(jsonId: { eq: $popularStoriesId }) {
      articles {
        id
        title
        path
        archive {
          title
          path
        }
        authors {
          name
        }
      }
    }

  ...headerFields @skip(if:$isEs)
	...footerFields @skip(if:$isEs)
  ...headerFieldsEs @include(if:$isEs)
  ...footerFieldsEs @include(if:$isEs)
	challenges: allItemsJson(
		filter: {content_type: {regex: "/timeline|article|challenge/"}, jsonId: {regex: $idRegex}}
		) {
		edges {
			node {
			title
			content_type
			path
			related_challenge {
				title
				path
			}
			core_curriculum {
				title
				path
			}
			}
		}
		}
		pages: allItemsJson(
		filter: {content_type: {eq: "page"}, jsonId: {regex: $idRegex}}
		) {
		edges {
			node {
			title
			content_type
			path
			}
		}
		}
		blog: allItemsJson(filter: {jsonId: {eq: $blog}}) {
		edges {
			node {
			title
			content_type
			path
			}
		}
		}
		translations: allItemsJson(filter: {jsonId: {eq: $translations}}) {
		edges {
			node {
			translations {
				id
				key
				value
			}
			}
		}
	}
  megaMenu: allItemsJson(filter: {content_type: {eq: "mega_menu"}}) {
		edges {
		  node {
			id
			jsonId
			content_type
			about {
			  link
			  title
			}
			our_team {
			  link
			  title
			}
      projects {
			  link
			  title
			}
			newsletter {
			  link
			  title
			}
			latest_stories {
			  uri
			  title
			}
			magazine {
			  link
			  title
			}
			educational_resources {
			  uri
			  title
			  image_url
			  image_alt
			}
			topics {
				display
				link
				langcode
			}
			latest_article_stories {
				id
				title
				path
				image {
					sizes {
						hero_lg
						hero_md
						hero_sm
						original
						near_square_md
					}
				}
				archive {
					id
					title
					path
				}
			}
			translated {
			  stories_and_resources
			  educational_resources
			  latest_stories
			  magazine
			}
			lang
		  }
		}
	}
}
`;
